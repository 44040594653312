<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-large
      rounded="lg"
    >
      <b-row class="auth-inner m-0">
        <!-- #region left side-->
        <b-col
          lg="5"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          style="background-color: white"
        >
          <b-col
            sm="12"
            md="12"
            lg="12"
            style="max-width:600px"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-1 font-weight-bold"
              title-tag="h2"
              style="font-size: 24px; font-weight: 600"
            >
              <div style="margin-bottom:60px">
                <protex-logo style="width: 160px !important; height: auto;" />
              </div>
              Sign in to your account
            </b-card-title>

            <br />
            <validation-observer ref="loginForm">
              <b-form class="auth-login-form mt-2" @submit.prevent="AwsSignIn">
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Email
                    </label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="email"
                      @input="val => (email = email.toLowerCase())"
                      style="height:58px; font-size:14px;  box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"
                      :state="errors.length > 0 && loginPressed ? false : null"
                      name="login-email"
                      placeholder="Email username"
                    />
                    <small v-if="loginPressed" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group style="padding-top: 6px; margin-bottom:20px">
                  <div class="d-flex justify-content-between">
                    <label
                      style="color: #596D80; font-weight: 500; font-size:14px"
                    >
                      Password
                    </label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="
                        errors.length > 0 && loginPressed ? 'is-invalid' : null
                      "
                      style="border-radius: 6px; box-shadow: 0px 24px 48px 0 rgba(41,58,79,0.15) !important;"
                    >
                      <b-form-input
                        id="login-password"
                        style="height:58px; font-size:14px;"
                        v-model="password"
                        :state="
                          errors.length > 0 && loginPressed ? false : null
                        "
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Enter password"
                      />
                      <b-input-group-append is-text>
                        <font-awesome-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-link
                      id="forgot-pw"
                      :to="{ name: 'auth-forgot-password' }"
                      style="
                        margin-top: 10px;
                        font-size: 14px;
                        float: right;
                        color: #7A8B99;
                        font-weight:500;
                        text-align: right;
                      "
                    >
                      <span>Forgot Password?</span>
                    </b-link>
                    <small v-if="loginPressed" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  id="loginBtn"
                  type="submit"
                  variant="primary"
                  style="color: white !important; font-size: 18px; font-weight:500; height:56px;"
                  block
                >
                  Log in
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- endregion -->
        <!-- #region right side -->
        <b-col
          v-if="!this.hideRightCol"
          lg="7"
          style="
            padding: 0px;
            background-repeat: no-repeat;
            background-size: cover;
          "
          :style="{ backgroundImage: 'url(' + bgImg + ')' }"
        >
          <img :src="bgImg" style="width: 100%; visibility:hidden" />
        </b-col>
        <!-- endregion -->
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */

//#region imports
// General
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ProtexLogo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import { Auth } from "aws-amplify";
import { loggedOnce } from "@/vue-apollo";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from 'axios';
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
const SIGNUP_API_ENDPOINT = process.env.VUE_APP_AZURE_SIGN_UP;
import {
  getHomeRouteForLoggedInUser,
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserEmail,
  getUserProfileImage
} from "@/auth/utils";
import { mapActions } from "vuex";

//Components
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay
} from "bootstrap-vue";
//#endregion

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  mixins: [togglePasswordVisibility],

  //#region components
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    ProtexLogo
  },
  //#endregion

  //#region data
  data() {
    return {
      loginPressed: false,
      hideRightCol: false,
      loading: false,
      status: "",
      password: "",
      email: "",
      sideImg: require("@/assets/images/pages/login-image.svg"),
      bgImg: "./bg-login.png",
      required
    };
  },
  //#endregion

  //#region mounted
  created() {
    // We may move GoogleAuth helper here
  },

  mounted() {
    this.$nextTick(() => {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
      window.addEventListener("resize", this.getDimensions);
    });

    if (localStorage.getItem("amplify-redirected-from-hosted-ui")) {
      // After creating the amplify User we need to setup the SPA
      console.log("MOUNTED IN LOGIN");
      // with essential data
      this.authUserGenerator();
    }

    if (this.$route.params.error === true) {
      this.$toast.warning({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Login Failed",
          text: "Authentication Failed"
        }
      });
    }
  },
  //#endregion

  //#region computed
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "fa-regular fa-eye-slash" : "fa-regular fa-eye";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-image.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  //#endregion

  //#region methods
  methods: {
    ...mapActions({
      fetchUserAndCompanyInfo: "userSettings/fetchUserAndCompanyInfo",
    }),
    async initiateGoogleAuth() {
      console.log("Signing in With Google");
      await Auth.federatedSignIn({ provider: "Google" });
    },





    

    async authUserGenerator() {
      try {
        this.loginPressed = true;

        loggedOnce.state = true;
        this.$ability.update([
          {
            action: "manage",
            subject: "all"
          }
        ]);
        Auth.currentSession()
          .then(data => {
            console.log(data);
            localStorage.setItem("JoT", JSON.stringify(data.idToken.jwtToken));
            localStorage.setItem(
              "refreshToken",
              JSON.stringify(data.refreshToken.token)
            );
            localStorage.setItem("Role", getUserRole());
            localStorage.setItem("SiteId", getUserSiteId());
            this.fetchUserAndCompanyInfo()
            this.$router
            .replace(getHomeRouteForLoggedInUser("admin"))
            .then(() => {
              this.loading = false;

              this.intro(userData.fullName.split(" ")[0]);
            });
        });
      } catch (loginErr) {
        console.info(loginErr);
        this.$toast.warning({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Login Failed",
            text: "Authentication Failed"
          }
        });
      }
    },

    getDimensions() {
      let width = document.documentElement.clientWidth;
      if (width <= 991) {
        this.hideRightCol = true;
      } else {
        this.hideRightCol = false;
      }
    },

    async AwsSignIn() {
      this.loginPressed = true;
      if (this.email.trim() != "" && this.password.trim() != "") {
        this.loading = true;
      }
      try {
        const user = await Auth.signIn(this.email, this.password);
        if (user.challengeName === "SMS_MFA") {
          this.$router.push({
            name: "auth-mfa",
            params: { userAuthObj: user }
          });
        } else {
          loggedOnce.state = true;
          localStorage.setItem(
            "refreshToken",
            JSON.stringify(user.signInUserSession.refreshToken.token)
          );
          localStorage.setItem(
            "JoT",
            JSON.stringify(user.signInUserSession.idToken.jwtToken)
          );
          localStorage.setItem("Role", getUserRole());

          let userData = {
            fullName: getUserName(),
            username: getUserName(),
            email: this.email,
            role: getUserRole(),
            ability: [
              {
                action: "manage",
                subject: "all"
              }
            ],
            accessToken: user.signInUserSession.accessToken.jwtToken,
            refreshToken: user.signInUserSession.refreshToken.token
          };

          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("userId", getUserId());

          this.$ability.update([
            {
              action: "manage",
              subject: "all"
            }
          ]);

          let data = await Auth.currentSession();
          localStorage.setItem("JoT", JSON.stringify(data.idToken.jwtToken));
          this.fetchUserAndCompanyInfo()
          this.$router
            .replace(getHomeRouteForLoggedInUser("admin"))
            .then(() => {
              this.loading = false;
              this.intro(userData.fullName.split(" ")[0]);
            });
          }
      } catch (error) {
        if (error.code === "UserNotConfirmedException") {
          this.$router.replace({
            name: "verify-account",
            params: { username: this.email }
          });
        } else {
          this.loading = false;
          this.$toast.warning({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Login Failed",
              text: error.message
            }
          });
        }
      }
    },

    intro(data) {
      this.$swal({
        title: `Welcome Back ${data}`,
        icon: "success",
        html: "Logged in successfully.",
        timer: 3000,
        confirmButtonColor: '#00A0F2',
        allowOutsideClick: true,
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: false
      })
    }
  }
  //#endregion
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";



#forgot-pw:hover {
  color: #00a0f3 !important;
}

#sign-up:hover {
  color: #00a0f3 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-size: 14px !important;
}
</style>

<style lang="scss">
#login-email.form-control {
  //  border: 2px solid rgba(160,170,178, 0.4); border-radius: 6px;
}

#login-password.form-control {
  // border: 2px solid rgba(160,170,178, 0.4); border-radius: 6px;
  // border-top-color: rgba(160,170,178, 0.4);
  // border-top-style: solid;
  // border-top-width: 1px;
  // border-right: none;
  // border-bottom-color: rgba(160,170,178, 0.4);
  // border-bottom-style: solid;
  // border-bottom-width: 1px;
  // border-left-color: rgba(160,170,178, 0.4);
  // border-left-style: solid;
  // border-left-width: 1px;
}




</style>
